import { useState, useRef, useEffect } from "react";
import Header from "./Header";
import ContactForm from "../View/ContactForm";

export const Home = () => {
  const [activeForm, setActiveForm] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (isInViewport(videoRef.current) && !isPlaying) {
        videoRef.current.volume = 0;
        videoRef.current.play();
        setIsPlaying(true);
      } else if (!isInViewport(videoRef.current) && isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPlaying]);

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  return (
    <div className="contHome">
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className="conTitleHome">
        <div id="decoHome"></div>
        <div id="decoHome2">
          <img src={require("../public/deco2.png")} />
        </div>
        <div id="decoHome3">
          <img src={require("../public/deco3.png")} />
        </div>
        <div id="decoHome4"></div>
        <div className="contTextHome">
          <h1>Your trusted destination for high quality building solutions</h1>
          <h2>
            We are leaders in the industry, providing a wide range of services
            that transform your ideas into impressive and durable structures
            made of excellent materials.
          </h2>
        </div>
        <div className="contImagesHome">
          <div>
            <img id="imagePortrait1" src={require("../public/homephto.png")} />
          </div>
          <div>
            <img id="imagePortrait2" src={require("../public/homephto2.png")} />
          </div>
        </div>
        <div className="contoutservices">
          <div className="cotnbtnsHome">
            <div className="btnServicesHome">
              <span>
                <a style={{ color: "#fff" }} href={"/Services"}>
                  Our Services
                </a>
              </span>
            </div>
            <div className="btnViewProHome">
              <span>
                <a href="/Portfolio">View projects</a>
              </span>
            </div>
            <div className="btnArrowHome">
              <svg
                width="80"
                height="20"
                viewBox="0 0 123 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M108.74 1L121.39 13.64L109.22 25.81"
                  stroke="#FF722B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M0 13.4H121.39"
                  stroke="#FF722B"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
            </div>
          </div>
          <div className="contCertificades">
            <div>
              <p>2004</p>
              <span>Since</span>
            </div>
            <div>
              <p>+430</p>
              <span>Completed Projects</span>
            </div>
            <div>
              <a
                href="https://www.bbb.org/us/tx/garland/profile/fence-contractors/javiers-fence-0875-90555763"
                target="_blank"
              >
                <img src={require("../public/certificado.png")} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 30 }}>
        <div className="contVideoHome">
          <div className="containerVideo">
            <div id="decoHome5"></div>
            <div id="decoHome6">
              <img src={require("../public/deco6.png")} />
            </div>
            <div id="decoHome8"></div>
            <video
              ref={videoRef}
              id="videoid"
              controls
              src={require("../public/videoHome.mp4")}
            ></video>
          </div>
          <div className="contTextVideo">
            <div>
              <h3>Guaranteed work </h3>
            </div>
            <div>
              <p>
                At Javier's Fence you will find innovative residential and
                commercial construction
              </p>
            </div>
          </div>
        </div>
        <div className="contWeServices">
          <div style={{ position: "relative" }}>
            <div id="decoHome7">
              <img src={require("../public/deco7.png")} />
            </div>
            <div className="conTitleWeServices">
              <h3>We Service to</h3>
            </div>
            <div className="listServices">
              <div>
                <ul style={{ color: "orange" }}>
                  <li>Dallas</li>
                  <li>Plano</li>
                  <li>Allen</li>
                  <li>Sachse</li>
                  <li>Irving</li>
                  <li>Grand Prairie</li>
                  <li>Arlington</li>
                </ul>
              </div>
              <div>
                <ul style={{ color: "orange" }}>
                  <li>Frisco</li>
                  <li>Mesquite</li>
                  <li>Prosper</li>
                  <li>Lewisville</li>
                  <li>Collin</li>
                  <li>Denton</li>
                  <li>Farmers Branch</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="contContacto">
            <div className="contRedes">
              <div className="redes">
                <a
                  href="https://api.whatsapp.com/send?phone=19728032896&text=Hello%2C%20I%20am%20interested%20in%20the%20service%20of%20...."
                  target="_blank"
                >
                  <svg
                    width="407"
                    height="407"
                    viewBox="0 0 407 407"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M407 203.5C407 315.89 315.89 407 203.5 407C91.1101 407 0 315.89 0 203.5C0 91.1101 91.1101 0 203.5 0C315.89 0 407 91.1101 407 203.5ZM13.5 203.5C13.7754 98.5663 98.566 13.6379 203.5 13.5C308.572 13.3619 393.724 98.4281 394 203.5C394.277 308.848 308.848 394.638 203.5 394.5C98.2895 394.362 13.2239 308.71 13.5 203.5Z"
                      fill="white"
                    />
                    <path
                      d="M281.049 124.969C260.473 104.344 233.071 93 203.951 93C143.844 93 94.933 141.911 94.933 202.018C94.933 221.219 99.942 239.978 109.469 256.527L94 313L151.799 297.826C167.71 306.518 185.634 311.085 203.902 311.085H203.951C264.009 311.085 314 262.174 314 202.067C314 172.946 301.625 145.594 281.049 124.969ZM203.951 292.719C187.647 292.719 171.687 288.348 157.79 280.098L154.5 278.134L120.223 287.121L129.357 253.679L127.196 250.241C118.112 235.804 113.348 219.156 113.348 202.018C113.348 152.076 154.009 111.415 204 111.415C228.21 111.415 250.946 120.844 268.036 137.982C285.125 155.121 295.634 177.857 295.585 202.067C295.585 252.058 253.893 292.719 203.951 292.719ZM253.647 224.853C250.946 223.478 237.54 216.897 235.036 216.013C232.531 215.08 230.714 214.638 228.897 217.388C227.08 220.138 221.875 226.228 220.254 228.094C218.683 229.911 217.063 230.156 214.362 228.781C198.353 220.777 187.844 214.491 177.286 196.371C174.487 191.558 180.085 191.902 185.29 181.491C186.174 179.674 185.732 178.103 185.045 176.728C184.357 175.353 178.906 161.946 176.647 156.496C174.438 151.192 172.179 151.929 170.509 151.83C168.938 151.732 167.121 151.732 165.304 151.732C163.487 151.732 160.54 152.42 158.036 155.121C155.531 157.871 148.509 164.451 148.509 177.857C148.509 191.263 158.281 204.228 159.607 206.045C160.982 207.862 178.808 235.362 206.161 247.196C223.446 254.661 230.223 255.299 238.866 254.022C244.121 253.237 254.973 247.442 257.232 241.058C259.491 234.674 259.491 229.223 258.804 228.094C258.165 226.866 256.348 226.179 253.647 224.853Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/Javiersfencer/"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 41.61 40.33"
                  >
                    <path
                      d="M20.81,0A21.09,21.09,0,0,1,35.52,5.91a19.73,19.73,0,0,1,0,28.51,21.27,21.27,0,0,1-29.43,0,19.73,19.73,0,0,1,0-28.51A21.1,21.1,0,0,1,20.81,0ZM34.38,7A19.61,19.61,0,0,0,7.25,7a18.17,18.17,0,0,0,0,26.3,19.63,19.63,0,0,0,27.13,0,18.22,18.22,0,0,0,0-26.3Z"
                      fill="#fff"
                    />
                    <path
                      d="M22.39,16V14.39a.88.88,0,0,1,.94-1h2.38V9.88H22.43c-3.65,0-4.47,2.61-4.47,4.31V16H15.84v4.12H18v10.3h4.25V20.16h3.15l.14-1.61L25.77,16Z"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/javiersfencer/"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 41.61 40.33"
                  >
                    <path
                      d="M20.81,0A21.07,21.07,0,0,1,35.52,5.91a19.73,19.73,0,0,1,0,28.51,21.27,21.27,0,0,1-29.43,0,19.73,19.73,0,0,1,0-28.51A21.11,21.11,0,0,1,20.81,0ZM34.38,7A19.61,19.61,0,0,0,7.25,7a18.17,18.17,0,0,0,0,26.3,19.63,19.63,0,0,0,27.13,0,18.22,18.22,0,0,0,0-26.3Z"
                      fill="#fff"
                    />
                    <path
                      d="M15.48,20.16a5.33,5.33,0,1,1,5.33,5.17,5.26,5.26,0,0,1-5.33-5.17Zm10.83-10.1h-11a4.86,4.86,0,0,0-4.93,4.78V25.5a4.87,4.87,0,0,0,4.93,4.78h11a4.86,4.86,0,0,0,4.93-4.78V14.84a4.86,4.86,0,0,0-4.93-4.78Zm-11,1.19h11A3.65,3.65,0,0,1,30,14.84V25.5a3.65,3.65,0,0,1-3.7,3.59h-11a3.65,3.65,0,0,1-3.7-3.59V14.84a3.65,3.65,0,0,1,3.7-3.59Zm12.1,1.51a1.05,1.05,0,1,0,1.08,1.05,1.07,1.07,0,0,0-1.08-1.05Zm.12,7.4a6.73,6.73,0,0,0-13.45,0,6.73,6.73,0,0,0,13.45,0Z"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </svg>
                </a>
              </div>
              <div
                onClick={() => {
                  setActiveForm(true);
                }}
                className="botonContact"
              >
                <span>CONTACT US</span>
              </div>
            </div>
            <div className="contInfoContact">
              <p>972 803 2896 </p>
              <p>javiersfencerepair@gmail.com</p>
              <p>P.O. Box 833005 Richardzon tx 75083</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
